<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>企业管理 </el-breadcrumb-item>
			<el-breadcrumb-item>企业信息更新</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-row :gutter="20">
				<el-col :span="12">
					<el-button type="primary" @click="goUpdateAccount">更新账户信息</el-button>
					<el-button type="primary" @click="goUpdateOperator">更新操作员信息</el-button>
				</el-col>
			</el-row>
			<!--列表-->
			<el-table :data="tableList" border>
				<el-table-column prop="taskType" label="任务类型">
					<template v-slot="scope">
						{{scope.row.taskType == 1 ? '更新操作员信息': '更新银行账户信息'}}
					</template>
				</el-table-column>
				<el-table-column prop="operationCode" label="操作编号"></el-table-column>
				<el-table-column prop="createTime" label="申请时间"></el-table-column>
				<el-table-column label="操作">
					<template v-slot="scope">
						<el-button type="primary" size="mini" v-if="scope.row.taskType === 1" @click="handleOperator(scope.row)">查看操作员</el-button>
						<el-button type="primary" size="mini" v-else @click="handleAccount(scope.row)">查看账户信息</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-card>
	</div>
</template>

<script>
  export default {
    name: "Update",
    data() {
      return {
        tableList: [],
      }
    },
	  mounted(){
      // 获取列表数据
		  this.getTableList()
	  },
	  methods: {
      // 获取列表数据
      async getTableList(){
        const {data: res} = await this.$auth.get('/cpiaoju-ticket/update-record/list');
        if (res.code !== 200) return this.$message.error(res.msg)
	      this.tableList = res.data.records
      },
      // 访问更新账户信息
      goUpdateAccount(){
        this.$router.push({name: 'companyAccount'})
      },
		  // 访问更新操作员信息
      goUpdateOperator(){
        this.$router.push({name: 'companyOperator'})
      },
		  // 访问账户信息详情页
      handleAccount(row){
        this.$router.push({
	        name: 'companyAccountDetail',
	        query: {
            id: row.id,
		        type: 2
	        }
        })
      },
		  // 访问操作员详情页
      handleOperator(row){
        this.$router.push({
	        name: 'companyOperatorDetail',
	        query: {
	          id: row.id,
		        type: 1
	        }
        })
      }
	  }
  }
</script>

<style scoped>
.el-table{
	margin-top: 20px;
}
</style>